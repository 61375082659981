<template>
  <div style="text-align: justify">
    <p class="mb-4">
      A buffer solution is prepared as described in a) below. A solution of a strong base is then
      added to this buffer solution as described in part b).
      <b>Note:</b> the
      <stemble-latex content="$\text{K}_{\text{a}}$" />
      of benzoic acid is
      <stemble-latex content="$6.5\times 10^{-5}.$" />
    </p>
    <p class="mb-3 pl-10">
      a) <stemble-latex content="$100.0\,\text{mL}$" />
      of
      <number-value :value="concHA" unit="\text{M}" />
      benzoic acid are mixed with
      <latex-number :number="molA" unit="\text{mol}" />
      of sodium benzoate plus
      <stemble-latex content="$100.0\,\text{mL}$" />
      of water.
    </p>
    <p class="mb-5 pl-10">
      b) <stemble-latex content="$1.0\,\text{mL}$" />
      of
      <number-value :value="concKOH" unit="\text{M KOH}" />
      is added to
      <stemble-latex content="$50.0\,\text{mL}$" />
      of the solution described in part a).
    </p>
    <p class="mb-4">For the two solutions described above, calculate:</p>
    <p class="mb-3 pl-10">i) The molar concentration of benzoic acid</p>

    <p class="mb-3 pl-10">ii) The molar concentration of the benzoate ion</p>

    <p class="mb-5 pl-10">iii) The pH of the solution</p>

    <p class="mb-8">
      In the procedure for this lab in the "Making a Buffer to a Given pH" section, the buffer you
      will be required to prepare must have a
      <b>pH of</b> <number-value :value="pH" />. Make note of this value on your report sheet and in
      your lab notebook.
    </p>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';
import NumberValue from '../NumberValue';

export default {
  name: 'UPEIBuffersPreLab',
  components: {
    StembleLatex,
    LatexNumber,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  computed: {
    concHA() {
      return this.taskState.getValueBySymbol('concHA').content;
    },
    pH() {
      return this.taskState.getValueBySymbol('pH').content;
    },
    concKOH() {
      return this.taskState.getValueBySymbol('concKOH').content;
    },
    molA() {
      return (this.concHA.toFloat() / 10).toFixed(3);
    },
  },
};
</script>
